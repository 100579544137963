let workPlace = window.location.hostname;
let baseUrlWithoutProxy = '';
let googleMapsKey = '';
let googlePlacesKey = '';
let customURL = '';
let proxy = '';
/*
    @ change the name of the hotel for each hotel app project name 
    @ list of hotels
        - LisboaAeroporto 
        - LisboaOriente
        - CaparicaMar
        - PortoCentro
        - PortoExpo
        - Leiria
*/
// Main Build Hotel Name 
const hotel = "Montijo";

if(hotel==="LisboaAeroporto") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "TRYP LISBOA AEROPORTO";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "tryp_aeroporto"
} else if (hotel==="LisboaOriente") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "TRYP LISBOA ORIENTE";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "tryp_oriente"
} else if (hotel==="CaparicaMar") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "TRYP CAPARICA MAR";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "tryp_caparica"
} else if (hotel==="PortoCentro") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "TRYP PORTO CENTRO";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "tryp_porto_centro"
} else if (hotel==="PortoExpo") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "TRYP PORTO EXPO";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "tryp_porto_expo"
} else if (hotel==="Leiria") {
    baseUrlWithoutProxy = "https://hoti.dev2.shareit.dev/";
    document.title = "TRYP LEIRIA";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "tryp_leiria"
} else if (hotel==="Montijo") {
    baseUrlWithoutProxy = "https://www.trypmontijo.com/";
    document.title = "TRYP MONTIJO";
    googleMapsKey = 'AIzaSyDMBFZPD46vKtg9NYt-KD743hwoht0DEao';
    googlePlacesKey = 'AIzaSyBZOrE7KwbKENLC8btDX4R4ayzQVZlCwCk';
    customURL = "tryp_montijo"
}
    
if (workPlace.includes('localhost') || workPlace.includes('dev2')) {
    googleMapsKey = 'AIzaSyC2pUy3WMzxafMKzE78pKi-swUpm-P_wJY';
    googlePlacesKey = 'AIzaSyC2pUy3WMzxafMKzE78pKi-swUpm-P_wJY';
}


const baseUrl = proxy + baseUrlWithoutProxy;
const prefix = 'cc_';
const apiPath = "wp-json/app/";
const apiNewPath = "hoti/";
const apiFunction = "?f=";
const apiHotel = "&h=";
const apiLang = "&lang=";
const apiID = "&id=";
const apiG_ID = "&g_id=";


const Global = {
    proxy: proxy,
    websiteBaseUrlWithoutProxy: baseUrlWithoutProxy,
    websiteBaseUrl: baseUrl,
    apiPath: apiPath,
    customURL: customURL,
    apiNewPath: apiNewPath,
    apiFunction: apiFunction,
    apiHotel: apiHotel,
    apiLang: apiLang,
    apiID: apiID,
    apiG_ID: apiG_ID,
    websiteUrl: baseUrl,
    googleMapsRouteUrl: 'https://www.google.com/maps/dir',
    googleMapsAPIKey: googleMapsKey,
    googlePlacesAPIKey: googlePlacesKey,
    googleMapsKey: 'https://maps.googleapis.com/maps/api/js?key='+googleMapsKey+'&v=3.exp&libraries=geometry,drawing,places',
    googleMapsWithCoordinates: 'https://www.google.com/maps/place/',
    localStorage: {
        generalSettings: prefix + 'generalSettings',
        googleDataSettings: 'googleDataSettings'
    }
};

export default Global;