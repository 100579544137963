import React from 'react';
import {Row, Col, Button} from 'reactstrap';
import Global from "../base/Global";
import './header.css';
import {postAPI} from "../base/API";
import {IntlContext} from "../../Utils/IntlProviderWrapper";
import LanguageSwitch from '../switchLanguage/LanguageSwitch';

export default class Header extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            routeLink: null,
            routeTitles: null,
            email: "",
            routeSent: false,
            loadingOnSend: false,
            isOpenedMenu: false
        };

        this.sendEmail = this.sendEmail.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    // Get global context
    static contextType = IntlContext; // get context
    
    componentDidMount() {
        this.setRouteLink();
    }
    
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.routeArray !== this.props.routeArray){
            const _this = this;
            setTimeout(function () {
                _this.setRouteLink();
            },200);
        }
    }

    // hide/show menu
    toggleMenu() {
        this.setState(prevState => ({
            isOpenedMenu: !prevState.isOpenedMenu
        }));
    }
    
    /*
    * Create the google maps route link and title to send by email
    * Set state
    * */
    setRouteLink(){
        let url = "";
        let routeTitles = "";
        
        for (let r=0; r < this.props.routeArray.length; r++) {
            
            if(r === 0){
                url += this.props.routeArray[r].reference;
                routeTitles += this.props.routeArray[r].title;
                
                continue;
            }
            url += "||" + this.props.routeArray[r].reference;
            routeTitles += "||" + this.props.routeArray[r].title;
        }

        this.setState({routeLink: url});
        this.setState({routeTitles: routeTitles});
    }

    handleEmail(event){
        let value = "";

        if(event.target.value !== undefined) {
            value = event.target.value;
        }
        this.setState({email: value});
    }
    
    clearRoutes(){
        this.setState({email: ""});
        this.setState({routeTitles: null});
        this.setState({routeLink: null});
        this.setState({routeSent: true});
        this.setState({loadingOnSend: false});
        
        const _this = this;
        setTimeout(function () {
            _this.setState({routeSent: false});
            _this.props.clearRoutes();
        },5000);
    }
    
    sendEmail(event){
        event.preventDefault();

        if(this.state.routeLink === null) return;
        //window.open(this.state.routeLink + "/" + this.state.routeTitles)
        this.setState({loadingOnSend: true});

        let formData = new FormData();
        formData.append('to', this.state.email);
        formData.append('body', this.state.routeLink);
        formData.append('pois', this.state.routeTitles);
  
        let url = Global.websiteBaseUrlWithoutProxy;

        if(this.context.locale === "en"){
            url += "en/";
        }
        else if(this.context.locale === "es"){
            url += "es/";
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                console.log(error);
            }
            if (data) {
                if(data.includes("Success")){
                    this.clearRoutes();
                }
            }
        }, url + Global.apiPath + "sendemail", formData);
    }
    
    render() {
        const {generalSettingsData, removeRoute, routeArray, isToShowRoutesModal, handleToggleModal, isToAnimCounter} = this.props;       
        
        return (
            <Row className="header-container">
                <Col>
                    <button onClick={this.toggleMenu} className="burger-menu" />
                    
                    <div data-open={this.state.isOpenedMenu ? "True" : "False"} className="mobile-menu">
                        <span onClick={this.toggleMenu}></span>
                        <h2>{generalSettingsData["Translates"]["DiscoverLocal"]}</h2>

                        <nav className="main-nav">
                            <a rel="noopener noreferrer" target="_blank"
                               href={Global.websiteBaseUrlWithoutProxy}>{generalSettingsData["Translates"]["TheHotel"]}</a>
                            <span onClick={handleToggleModal}>{generalSettingsData["Translates"]["MyRoute"]}</span>
                        </nav>

                        <LanguageSwitch/>
                    </div>
                    
                    <figure className="logo">
                        <a href="/">
                            <img className="desktop-logo" src={generalSettingsData["LogoDesktop"]} alt={generalSettingsData["Translates"]["HotelName"]} /> 
                            <img className="mobile-logo" src={generalSettingsData["LogoMobile"]} alt={generalSettingsData["Translates"]["HotelName"]} /> 
                        </a>
                    </figure>

                    <Button className="modal-toogle-btn" onClick={handleToggleModal}>
                        <span className={isToAnimCounter ? "to-anim anim" : "to-anim"}>{routeArray.length}</span>
                    </Button>
                    
                    <RoutesContainer
                        generalSettingsData={generalSettingsData}
                        removeRoute={removeRoute}
                        routeArray={routeArray}
                        isToShowRoutesModal={isToShowRoutesModal}
                        sendEmail={this.sendEmail}
                        handleToggleModal={handleToggleModal}
                        handleEmail={this.handleEmail}
                        routeSent={this.state.routeSent}
                        loadingOnSend={this.state.loadingOnSend}
                    />
                    
                </Col>
            </Row>
        )
    }
}

/*
* Routes Container
* */
class RoutesContainer extends React.PureComponent {

    render() {
        const {generalSettingsData, removeRoute, routeArray, isToShowRoutesModal, handleToggleModal, sendEmail, handleEmail, routeSent, loadingOnSend} = this.props;
      
        if(!routeArray.length) {
            return "";
        }
        
        return (
            <div className="routes-modal-container" data-istoshow={isToShowRoutesModal}>
                <div>
                    <h2>{generalSettingsData["Translates"]["MyRoute"]}</h2>
                    <Button className="close-large" onClick={handleToggleModal}></Button>
                </div>
                <ul className="routes-list">
                    {routeArray.map((r) =>
                        <li key={r.title}>
                            <span data-type={r.type} className={r.type + " icon"} style={{backgroundImage: "url(/content/images/Categories/ExploreMap/" + r.type + ".svg)"}}> </span>
                            <span className="title">{r.title}</span>

                            <Button onClick={removeRoute.bind(this, r.title)} className="close-small">X</Button>
                        </li>
                    )}
                </ul>

                <form className={routeSent ? "hidden" : ""} onSubmit={sendEmail}>
                    <fieldset>
                        <label>{generalSettingsData["Translates"]["WriteYourEmail"]}</label>
                        <input pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" autoComplete="off" onChange={handleEmail} required type="email" name="email" placeholder={generalSettingsData["Translates"]["WriteYourEmail"]} />
                    </fieldset>

                    <Button disabled={loadingOnSend} type="submit" className="send-to-google-btn">
                        {loadingOnSend ? generalSettingsData["Translates"]["SendingEmail"] : generalSettingsData["Translates"]["SendToGoogleMaps "]}
                    </Button>
                </form>
                <div className={routeSent ? "route-sent" : "hidden"}>
                    {generalSettingsData["Translates"]["RouteSent"]}
                </div>
            </div>
        )
    }
}